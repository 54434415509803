.high .ant-tabs-ink-bar  { 
    background-color: #b91a1a;
}
.medium .ant-tabs-ink-bar  { 
    background-color: #ebb000;
}
.low .ant-tabs-ink-bar  { 
    background-color: #4e4e4e;
}

.riskAlertsCount {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 10px;
}